import React from 'react'
import { Container } from 'components/atoms/Container/Container'
import styled from 'styled-components'
import { MediaQueries } from 'styles'
import { getDelay } from 'utils'
import { Feature } from './Feature'
import { features } from 'constants/features'

export const FeatureSection = () => (
  <SectionWrapper id="features">
    <Container size="medium">
      <ContentWrapper>
        {features.map((feature, idx: number) => (
          <Feature
            key={idx}
            title={feature.title}
            description={feature.description}
            imageURL={feature.imageURL}
            data-aos="fade-up"
            data-aos-delay={getDelay(250 * idx)}
          />
        ))}
      </ContentWrapper>
    </Container>
  </SectionWrapper>
)

const SectionWrapper = styled.section`
  padding: 80px 0;

  ${MediaQueries.medium} {
    padding: 40px 0;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;

  ${MediaQueries.medium} {
    align-items: center;
    gap: 40px;
  }
`
