import React from 'react'
import { Container } from 'components/atoms/Container/Container'
import styled from 'styled-components'
import { Box } from 'components/molecule/Box/Box'
import { MediaQueries, zIndexes } from 'styles'
import { getDelay } from 'utils'
import { prices } from 'constants/prices'
import { JoinButton } from 'components/atoms/JoinButton'

export const PriceSection = () => (
  <Container size="medium">
    <SectionWrapper id="pricing">
      <ContentWrapper>
        <BoxWrapper>
          {prices.map((price, idx: number) => (
            <Box
              key={idx}
              title={price.title}
              description={price.description}
              amount={price.amount}
              data-aos="fade-up"
              data-aos-delay={getDelay(250 * idx)}
            />
          ))}
        </BoxWrapper>
        <JoinButton data-aos="fade-up" data-aos-delay={getDelay(1000)}/>
      </ContentWrapper>
    </SectionWrapper>
  </Container>
)

const SectionWrapper = styled.section`
  padding: 80px 0 100px;
  position: relative;

  &:after{
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) =>
    `linear-gradient(45deg, ${theme.colors.lightBackground}, ${theme.colors.secondaryLight})`};
    position: absolute;
    bottom: -100px;
    right: -50%;
    border-radius: 50%;
    z-index: ${zIndexes.belowContent};
    opacity: 0.3;
    filter: blur(250px);

    ${MediaQueries.medium} {
      right: 0;
  }
  }

  ${MediaQueries.medium} {
    padding: 40px 0 80px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
`

const BoxWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;

  ${MediaQueries.medium} {
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
  }

  ${MediaQueries.small} {
    grid-row-gap: 24px;
  }
`
