
type Price = {
  title: string,
  description: string,
  amount: string,
}

export const prices: Price[] = [
  {
    title: 'Personal',
    description: 'Create your own cards and learn at your own pace. Unlimited and free forever',
    amount: '0',
  },
  {
    title: 'Premium',
    description: `Get access to curated decks created by verified teachers.
    Invite your tutor and get create cards together. Offline mode and card sharing included.`,
    amount: '3.99',
  },
  {
    title: 'Ultimate',
    description: 'Train your grammar with our embedded AI grammar coach. Get access to all premium features.',
    amount: '8.99',
  },
]
