import styled from 'styled-components'
import { LinkButton } from 'components/atoms/LinkButton'
import { ArrowIcon } from 'components/atoms/Icons'
import React from 'react'
import { MediaQueries } from 'styles'

export const JoinButton = () => {
  return (
    <StyledButton href="https://join.lingo.cards/waitlist" target="_blank">
      Join the waitlist
      <ArrowIcon viewBox={21} size={21} />
    </StyledButton>
  )
}

const StyledButton = styled(LinkButton)`
  gap: 12px;

  ${MediaQueries.medium} {
    font-size: 18px;
    line-height: 25px;
  }

  ${MediaQueries.small} {
   width: 100%;
   max-width: unset;
  }
`
