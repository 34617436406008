import styled, { css } from 'styled-components'
import { Animation } from 'styles'

interface LinkButtonProps {
  width?: number,
  light?: boolean,
  size?: 'small' | 'medium' | 'large',
}

export const LinkButton = styled.a<LinkButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 100%;
  max-width: ${({ width }) => (width ? `${width}px` : '300px')};
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme }) => theme.colors.secondaryLight};
  background-color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) =>
    `linear-gradient(45deg, ${theme.colors.lightBackground}, ${theme.colors.secondaryLight})`};
  cursor: pointer;
  text-decoration: none;
  transition: ${Animation.default};

  &:hover {
    background: ${({ theme }) =>
    `linear-gradient(45deg, ${theme.colors.tertiaryLight}, ${theme.colors.tertiary})`};
    border-color: ${({ theme }) => theme.colors.tertiary};
  }

  ${({ size }) => {
    switch (size) {
      case 'large':
      default:
        return ButtonTextLargeSize
      case 'medium':
        return ButtonTextMediumSize
      case 'small':
        return ButtonTextSmallSize
    }
  }}
`

const ButtonTextSmallSize = css`
  font-size: 16px;
  line-height: 28px;
`

const ButtonTextMediumSize = css`
  font-size: 18px;
  line-height: 28px;
`

const ButtonTextLargeSize = css`
  font-size: 22px;
  line-height: 32px;
`
