import React from 'react'
import styled from 'styled-components'
import { TextHeader, TextHighlight } from 'components/atoms/typography'
import { TextBody } from 'components/atoms/typography'
import { Container } from 'components/atoms/Container/Container'
import { MediaQueries, zIndexes } from 'styles'
import { getDelay } from 'utils'
import { JoinButton } from 'components/atoms/JoinButton'

export const HeroSection = () => (
  <HeroWrapper>
    <Container size="full">
      <ContentWrapper>
        <TextWrapper>
          <TextHeader size="large" light data-aos="fade-up" data-aos-delay={getDelay(250)}>
            Learn grammar and writing with <br/>
            <TextHighlight>Lingo Cards</TextHighlight>
          </TextHeader>
          <StyledTextBody size="large" data-aos="fade-up" data-aos-delay={getDelay(500)}>
            Train your grammar and writing skills with interactive flashcards.
            Create custom decks, fill the gaps, and get instant feedback.
            Memorize through repetition!
          </StyledTextBody>
        </TextWrapper>
        <JoinButton data-aos="fade-up" data-aos-delay={getDelay(750)}/>
      </ContentWrapper>
    </Container>
  </HeroWrapper>
)

const HeroWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 200px 0 80px;
  color: ${({ theme }) => theme.colors.textDark};
  position: relative;

  &:before{
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) =>
    `linear-gradient(45deg, ${theme.colors.lightBackground}, ${theme.colors.secondaryLight})`};
    position: absolute;
    top: -100px;
    left: -50%;
    border-radius: 50%;
    z-index: ${zIndexes.belowContent};
    opacity: 0.3;
    filter: blur(200px);
  }

  ${MediaQueries.small} {
    padding: 150px 0 40px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  max-width: 920px;
  margin: 0 auto;

  ${MediaQueries.medium} {
    padding: 0 24px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
  text-align: center;
`

const StyledTextBody = styled(TextBody)`
  color: ${({ theme }) => theme.colors.textLight};
`
