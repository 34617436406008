import * as React from 'react'
import type { HeadFC } from 'gatsby'
import { Layout } from 'components/Layout'
import { HeroSection, FeatureSection, PriceSection } from 'components/organisms/sections'
import { SEO } from 'components/organisms/SEO'

const IndexPage = () => {
  return (
    <Layout>
      <HeroSection />
      <FeatureSection />
      <PriceSection />
    </Layout>
  )
}

export default IndexPage

export const Head: HeadFC = () => <SEO />
