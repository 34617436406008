import React from 'react'
import styled from 'styled-components'
import { TextBody, TextHeader } from 'components/atoms/typography'
import { MediaQueries } from 'styles'

interface BoxProps {
  title: string,
  description: string,
  amount: string,
}
export const Box = ({ title, description, amount, ...props }: BoxProps) => (
  <BoxWrapper {...props}>
    <BoxContent>
      <TextHeader size="small" bold>{title}</TextHeader>
      <TextBody size="small" center>{description}</TextBody>
    </BoxContent>
    <PriceBox><PriceText>$</PriceText><TextHeader>{amount}</TextHeader>  <PriceText>/month</PriceText></PriceBox>
  </BoxWrapper>
)

const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`
const PriceBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const PriceText = styled.span`
font-size: 14px;
color: ${({ theme }) => theme.colors.textLight};
`

const BoxWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 264px;
  gap: 16px;
  justify-items: center;
  align-items: self-end;
  padding: 40px;
  border: 1px solid ${({ theme }) => theme.colors.textLight};
  border-radius: 4px;

  &:nth-child(2n){
    ${PriceBox},
    ${TextHeader}{
      width: fit-content;
  background: ${({ theme }) =>
    `linear-gradient(0deg, ${theme.colors.lightBackground} 100%, transparent 0)`};
    padding: 0 4px;
    }
  }

  ${MediaQueries.medium} {
    gap: 24px;
    padding: 24px;
    min-height: unset;
  }
`
