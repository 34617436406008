import React from 'react'
import { TextBody, TextHeader } from 'components/atoms/typography'
import styled from 'styled-components'
import { Animation, MediaQueries } from 'styles'
import { getDelay } from 'utils'

interface FeatureProps{
  title: string,
  description: string,
  imageURL: string,
}

export const Feature = ({ title, description, imageURL }:FeatureProps) => (
  <Wrapper>
    <TextContent>
      <FeatureTextHeader data-aos="fade-up" data-aos-delay={getDelay(250)}>
        {title}
      </FeatureTextHeader>
      <TextBody size="medium" data-aos="fade-up" data-aos-delay={getDelay(500)}>
        {description}
      </TextBody>
    </TextContent>
    <ImageWrapper
      data-aos="flip-up"
      data-aos-delay={getDelay(750)}
    >
      <img src={imageURL} alt="app.truefi.io" />
    </ImageWrapper>
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;

  &:nth-child(2n){
    flex-direction: row-reverse;

    ${MediaQueries.medium} {
    flex-direction: column-reverse;}
  }

  ${MediaQueries.medium} {
    flex-direction: column-reverse;
    align-items: center;
    gap: 24px;
  }
`

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 50%;
  width: 100%;
  cursor: pointer;

  ${MediaQueries.medium} {
    align-items: center;
    max-width: 100%;
    text-align: center;
  }
`

const FeatureTextHeader = styled(TextHeader)`
width: fit-content;
  background: ${({ theme }) =>
    `linear-gradient(0deg, ${theme.colors.lightBackground} 50%, transparent 0)`};
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  transition: ${Animation.default};

  img {
    max-width: 100%;
    max-height: 500px;
  }
`
