import screen1 from 'images/screens/screen1.png'
import screen2 from 'images/screens/screen2.png'
import screen3 from 'images/screens/screen3.png'

type Feature = {
  title: string,
  description: string,
  imageURL: string,
}

export const features: Feature[] = [
  {
    title: 'Create your own cards',
    description: `Build cards with gaps to fill or open questions
    to train your writing and boost your learning`,
    imageURL: screen1,
  },
  {
    title: 'Learn with teachers',
    description: `Your tutor can create cards and share them with you.
    Get access to curated list of decks created by verified teachers`,
    imageURL: screen2,
  },
  {
    title: 'AI coach on the go',
    description: `Our pre-trained AI corrects and explains grammar mistakes.
    And the best part? It works offline!`,
    imageURL: screen3,
  },
]
